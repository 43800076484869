<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <!-- 单独用户 -->
        <el-select
          v-model="params.adminUserId"
          filterable
          clearable
          placeholder="用户名称"
        >
          <el-option
            v-for="item in putUserList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- <el-select
          v-model="params.isPut"
          placeholder="分类"
          style="width: 100px"
        >
          <el-option label="运营" :value="0"></el-option>
          <el-option label="投手" :value="1"></el-option>
        </el-select> -->

        <!-- 投手部分分类 -->
        <!-- <el-select
          v-if="params.isPut"
          v-model="params.labelType"
          filterable
          clearable
          placeholder="部分分类"
        >
          <el-option label="第一部分" :value="1"> </el-option>
          <el-option label="第二部分" :value="2"> </el-option>
          <el-option label="第三部分" :value="3"> </el-option>
        </el-select> -->
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
        <el-button
          style="margin-left: auto"
          type="warning"
          @click="handleShowDetail"
        >
          薪资
        </el-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      header-row-class-name="table-header"
      ref="userConsumeTable"
      class="consume-table"
      :span-method="objectSpanMethod"
      :data="tableData"
      style="width: 100%"
      height="640px"
      v-loading="loading"
      :row-class-name="tableRowClassName"
      empty-text="暂无数据"
    >
      <el-table-column prop="adminUserName" label="用户"> </el-table-column>
      <el-table-column prop="years" label="年份"> </el-table-column>
      <el-table-column min-width="100px" prop="costAmount" label="现金消耗">
      </el-table-column>
      <el-table-column min-width="100px" prop="afterTaxAmount" label="充值">
      </el-table-column>
      <el-table-column min-width="100px" prop="profitAmount" label="利润">
      </el-table-column>
      <el-table-column min-width="100px" prop="profitRate" label="利润率">
      </el-table-column>
      <el-table-column min-width="100px" prop="issuedProfit" label="已发利润">
      </el-table-column>
      <template v-if="extraHeader.length > 0">
        <el-table-column
          v-for="(head, index) in extraHeader"
          :key="index + 'profitList'"
          :label="head"
          prop="head"
          min-width="120px"
        >
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.profitList[index]"
              @hide="editRate = null"
              @show="editRate = scope.row.profitList[index].rate"
              placement="bottom-start"
              title="设置利率"
              width="270"
              trigger="click"
            >
              <el-input-number
                v-model="editRate"
                controls-position="right"
                :min="0"
                :max="100"
              ></el-input-number>
              <el-button
                type="primary"
                @click="handleEdit(scope.row.profitList[index])"
                style="margin-left: 15px"
                :loading="editLoading"
                >确 定
              </el-button>
              <div style="cursor: pointer" slot="reference">
                <i class="remark-num">{{
                  scope.row.profitList[index].afterTaxProfit
                }}</i>
                <p style="line-height: 20px">
                  税前：{{ scope.row.profitList[index].profit }}
                </p>
                <span>利率：{{ scope.row.profitList[index].rate }}%</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      style="margin: 10px 0; text-align: right"
      background
      @current-change="handleStaticPageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="totalPage"
    >
    </el-pagination>
    <!-- 公共抽屉 -->
    <el-drawer
      title="详情"
      :visible.sync="showDetail"
      size="90%"
      :destroy-on-close="true"
      :show-close="true"
    >
      <salaryData v-if="showDetail" />
    </el-drawer>
  </div>
</template>

<script>
import salaryData from "./salaryData.vue";
import SelectField from "@/components/SelectField";
import { getFinanceSalaryNew, setSalaryRateNew } from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { mapGetters, mapActions } from "vuex";
let timer = null;
export default {
  name: "userConsume",
  components: {
    SelectField,
  },
  computed: {
    ...mapGetters(["operateUserList", "putUserList"]),
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      tableData: [],
      page: 1,
      totalPage: 0,
      extraHeader: [],
      pageSize: 30,
      editRate: null,
      editLoading: false,
      spanArr: [],
      spanArr2: [],
      pos: 0,
      pos2: 0,
      // 侧栏相关
      showDetail: false,
    };
  },
  components: {
    salaryData,
  },
  methods: {
    // 合并同名数据
    tableRowClassName({ rowIndex }) {
      if (this.spanArr[rowIndex] !== 0 && rowIndex !== 0) {
        return "table-top-red";
      }
      return "";
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    handleEdit(item) {
      this.editLoading = true;
      setSalaryRateNew(item.id, {
        rate: this.editRate,
      })
        .then((res) => {
          item.afterTaxProfit = res;
          item.rate = this.editRate;
          document.body.click();
        })
        .finally(() => (this.editLoading = false));
    },
    getSpanArr(data) {
      // spanArr和pos需要定义
      this.spanArr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].adminUserName === data[i - 1].adminUserName) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    getSpanArr2(data) {
      // spanArr和pos需要定义
      this.spanArr2 = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr2.push(1);
          this.pos2 = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (
            data[i].issuedProfit === data[i - 1].issuedProfit &&
            this.spanArr2[this.pos2] < this.spanArr[this.pos2] //合并个数不能超过用户名
          ) {
            this.spanArr2[this.pos2] += 1;
            this.spanArr2.push(0);
          } else {
            this.spanArr2.push(1);
            this.pos2 = i;
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.label === "用户") {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col,
        };
      }
      if (column.label === "已发利润") {
        const _row = this.spanArr2[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    handleShowDetail() {
      this.showDetail = true;
    },
    // 重置操作
    handleReset() {
      this.page = 1;
      this.params.adminUserId = null;
      this.params.isPut = 0;
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 静态分页
    handleStaticPageChange(page) {
      this.page = page;
      this.tableData = this.dataList.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
      this.getSpanArr(this.tableData);
      this.getSpanArr2(this.tableData);
    },
    // 广告数据获取
    getData(flag) {
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      tmpform = {
        adminUserId: this.params.adminUserId,
      };
      this.loading = true;
      getFinanceSalaryNew(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          this.extraHeader = res.headers;
          this.dataList = res.list;
          this.totalPage = this.dataList.length;
          this.handleStaticPageChange(1);
          // 缓存参数
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpform,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 处理字段
  },
  created() {
    let tmpYear = new Date().getFullYear() - 2019;
    this.pageSize = Math.ceil(30 / tmpYear) * tmpYear; //方便已年限作静态分页
    this.$set(this.params, "isPut", 0);
    this.$set(this.params, "labelType", 2);
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.getData();
    this.$set(this.params, "isTest", 0);
  },
};
</script>

<style lang="scss" scoped>
/**
**/

.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
.el-table--small {
  font-size: 12px !important;
}
</style>
